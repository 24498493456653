// Fixed options for various selectors

export const languageOptions: Language[] = ["da-DK", "en-US", "fo-FO", "de-DE", "sv-SE"];

export const deliveryMethodOptions = ["Afhentning", "Udbringning"];

export const timeslotIntervalOptions = [5, 15, 60];

export const receiptTypeOptions = ["email", "sms"];

export const clubLoginTypeOptions = ["email", "sms"];

export const orderIdTypeOptions = ["internal", "external"];
